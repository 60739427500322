<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">優惠活動</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增優惠活動
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <vxe-input
              type="search"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              clearable="true"
              @keyup="
                e => {
                  if (e.$event.keyCode === 13) grid.refresh();
                }
              "
              @clear="grid.refresh()"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <!-- <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button> -->
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a> -->
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @edit="onGridEdit">
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              :title-width="100"
              title-align="right"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="onFormSubmit(row, submit)"
            >
              <template #column-photo="{ data }">
                <div class="flex justify-center">
                  <FileUploader
                    :ref="el => (uploader = el)"
                    id="activity-photo-uri"
                    style="min-width: 150px; max-width: 150px; min-height: 150px; max-height: 180px;"
                    mode="image"
                    :modelValue="data.Photo?.Uri"
                    :action="uploadAction"
                    :autoUpload="true"
                    :limitedWidth="150"
                    :limitedHeight="150"
                    @update:modelValue="
                      value => {
                        if (data.Photo) data.Photo.Uri = value;
                        else data.Photo = { Uri: value };
                      }
                    "
                  />
                </div>
              </template>
              <template #column-rules="{ data }">
                <SelectBox
                  :transfer="true"
                  v-model="data.RuleIds"
                  v-bind="ruleSelectOptions"
                />
              </template>
              <template #column-description="{ data }" v-if="row.Content">
                <CKEditor
                  v-model="data.Content.Content"
                  v-bind="editorOptions"
                />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            >
            </vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <vxe-modal :title="`檔案管理`" transfer resize="true" width="960" destroy-on-close v-model="isModalPopup">
      <FileManager @insert="insertImage"></FileManager>
    </vxe-modal>
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}

.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import CloudFun from "@cloudfun/core";
import { helper as $h } from "@/utils/helper";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import FileManager from "@/global-components/file-manager/Main.vue";
import FileManagerIcon from '!!raw-loader!@/assets/images/file.svg'; // eslint-disable-line

const isModalPopup = ref(false);
let editor: any = null;
class FileManagePlugin extends ClassicEditor.Plugin {
  init() {
    // console.log('File Manager was initialized.');
    editor = this.editor;
    // The button must be registered among the UI components of the editor
    // to be displayed in the toolbar.
    editor.ui.componentFactory.add('filemanage', () => {
      // The button will be an instance of ButtonView.
      const button = new ClassicEditor.ButtonView();

      button.set({
        label: 'File Manager',
        tooltip: '開啟檔案總管插入圖片',
        icon: FileManagerIcon
      });
      // Execute a callback function when the button is clicked.
      button.on('execute', () => {
        isModalPopup.value = true;
      });

      return button;
    });
  }
}

export default defineComponent({
  components: {
    Grid,
    FileUploader,
    SelectBox,
    FileManager
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref({});

    const gridOptions: GridOptions = {
      title: "優惠活動",
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      columns: [
        {
          field: "Number",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Type",
          title: "類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.ActivityType).find(
                  e => e.Value === cellValue
                )?.Name
              : undefined
        },
        {
          field: "StartDate",
          title: "開始時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "EndDate",
          title: "結束時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) =>
            $h.formatDate(cellValue, "YYYY/MM/DD HH:mm:ss")
        },
        {
          field: "Published",
          title: "已發佈",
          showHeaderOverflow: true,
          showOverflow: true,
          align: "center",
          width: 100,
          sortable: true,
          resizable: false,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        }
      ],
      promises: {
        query: model
          ? params => model.dispatch("activity/query", params)
          : undefined,
        queryAll: model ? () => model.dispatch("activity/query") : undefined,
        save: model
          ? params => model.dispatch("activity/save", params)
          : undefined
      },
      modalConfig: { width: "80%", height: "80%", showFooter: true }
    };

    const formOptions: VxeFormProps = {
      items: [
        { field: "Photo.Uri", span: 24, slots: { default: "column-photo" } },
        {
          field: "Number",
          title: "編號",
          span: 12,
          itemRender: {
            name: "$input",
            props: { placeholder: "未輸入則系統自動產生" }
          }
        },
        {
          field: "Name",
          title: "名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } }
        },
        {
          field: "Type",
          title: "類型",
          span: 12,
          itemRender: {
            name: "$select",
            props: { placeholder: "類型" },
            options: model
              ? Object.values(model.enums.ActivityType).map(e => {
                  return { label: e.Name, value: e.Value };
                })
              : []
          }
        },
        {
          field: "StartDate",
          title: "開始時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", placeholder: "請輸入日期" }
          }
        },
        {
          field: "EndDate",
          title: "結束時間",
          span: 12,
          itemRender: {
            name: "$input",
            props: { type: "datetime", placeholder: "請輸入日期" }
          }
        },
        {
          field: "Published",
          title: "已發佈",
          span: 12,
          itemRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否" }
          }
        },
        {
          field: "Rules",
          title: "活動規則",
          span: 24,
          slots: { default: "column-rules" }
        },
        {
          field: "Content",
          title: "說明",
          span: 24,
          slots: { default: "column-description" }
        }
      ],
      rules: {
        Name: [{ required: true }],
        Type: [{ required: true }],
        StartTime: [{ required: true }]
      }
    };

    const ruleSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇規則",
      textField: "Name",
      valueField: "Id",
      multiselect: true,
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      promises: {
        find: value => model!.dispatch("marketingRule/find", value), // eslint-disable-line
        query: params => model!.dispatch("marketingRule/query", params) // eslint-disable-line
      }
    };

    const editorOptions = {
      editor: ClassicEditor.Editor,
      config: {
        mediaEmbed: {
          previewsInData: true
        },
        extraPlugins: [UploadAdapterPlugin, "MediaEmbed", FileManagePlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            'filemanage',
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown", "Title"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/Files`,
      ruleSelectOptions,
      editorOptions,
      isModalPopup: isModalPopup
    };
  },
  methods: {
    async onGridEdit(row: any, callback: any) {
      if (!row.Id) {
        row.Type = 1;
        row.Published = false;
        row.Content = {};
        row.RuleIds = [];
      } else {
        const entity = await this.$model.dispatch("activity/find", row.Id);
        console.log("🚀 ~ file: Main.vue:495 ~ onGridEdit ~ entity", entity);

        if (entity.Content === null || entity.Content.Content === null)
          entity.Content = { Content: "" };
        Object.assign(row, entity);
        row.RuleIds = row.Rules?.sort((a: any, b: any) => a.Priority - b.Priority).map((e: any) => e.MarketingRuleId) || [];
      }
      callback();
    },
    insertImage(imgArray: any) {
      const images = imgArray.map(function (value: any) {
        return value.dataItem.url;
      })
      isModalPopup.value = false;
      editor.execute('insertImage', { source: images });
    },
    onFormSubmit(row: any, submit: any) {
      row.Rules = row.RuleIds.map((id: number) => ({ Id: id }));
      submit();
    }
  }
});
</script>
